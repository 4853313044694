const getters = {
  token: state => state.auth.token,
  /**
   *
   * @param state
   * @returns {UserProfile}
   */
  user: state => state.auth.user,
  setting: state => state.app.setting,
  my_room: state => state.my_room.myRoom
}
export default getters

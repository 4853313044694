import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'
import auth from './modules/auth'
import app from './modules/app'
import my_room from "./modules/my_room";
Vue.use(Vuex)

const store = new Vuex.Store({
  modules: {
    auth,
    app,
    my_room
  },
  getters
})

export default store

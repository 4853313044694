import axios from 'axios'
import {MessageBox, Message} from 'element-ui'
import store from '@/store'
import {getToken} from '@/utils/auth'
import router from "@/router";

const service = axios.create({
    baseURL: process.env.VUE_APP_API_URL, // url = base url + request url
    // withCredentials: true, // send cookies when cross-domain requests
    timeout: 20000 // request timeout
})

service.interceptors.request.use(
    config => {
        config.headers['Content-Type'] = 'application/json'
        config.headers['Accept'] = 'application/json,text/html, */*'
        config.headers['X-PLATFORM'] = 'web'
        if (store.getters.token) {
            config.headers['Authorization'] = 'Bearer ' + getToken()
        } else {
            config.headers['Authorization'] = 'Bearer '
        }
        config.url = config.url.replace(config.baseURL + '/', config.baseURL)
        return config
    },
    error => {
        return Promise.reject(error)
    }
)

// response interceptor
service.interceptors.response.use(
    response => {
        const res = response.data
        if (res.hasOwnProperty('error')) {

            if (res.error.code === 401) {
                // to re-login
                MessageBox.confirm('您的登录状态已过期，请重新登录', '重新登录', {
                    confirmButtonText: '重新登录',
                    showCancelButton: false,
                    showClose: false,
                    closeOnClickModal: false,
                    closeOnPressEscape: false,
                    closeOnHashChange: false,
                    type: 'warning'
                }).then(() => {
                    store.dispatch('auth/resetToken').then(() => {
                        router.push({
                            path: `/auth/login?redirect=${router.currentRoute.path}`
                        })
                    })
                })
            } else {
                Message({
                    message: res.error.message || 'Error',
                    type: 'error',
                    duration: 5 * 1000
                })
                return Promise.reject(new Error(res.error.message || 'Error'))
            }
        } else {
            return res
        }
    },
    error => {
        Message({
            message: error.message,
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)


export default class Requester {
    fetchAsSingle(path, mapper, params = {}) {
        return service.get(path, {params: params}).then(this._single).then(mapper);
    }

    postAsSingle(path, mapper, data = {}) {
        return service.post(path, data).then(this._single).then(mapper);
    }

    postAsBean(path, data = {}) {
        return service.post(path, data).then(this._single);
    }

    fetchAsList(path, mapper, params = {}) {
        return service.get(path, {params: params}).then(this._list).then(list => list.map(item => mapper(item)));
    }

    fetchAsPage(path, mapper, params = {page: 1}) {
        return service.get(path, {params: params}).then(this._page).then((response) => {
            response['data'] = response['data'].map(item => mapper(item))
            return response;
        });
    }


    _single(response) {
        if (Array.isArray(response['data']) && response['data'].length === 0) {
            return {};
        }
        return response['data']
    }

    _list(response) {
        if (!Array.isArray(response['data'])) {
            return Array.from(response['data'])
        }
        return response['data']
    }

    _page(response) {
        if (!Array.isArray(response['data'])) {
            response['data'] = Array.from(response['data'])
        }
        if (!response['meta']) {
            response['meta'] = null
        } else {
            for (let k in response['meta']) {
                response['meta'][k] = parseInt(response['meta'][k])
            }
        }
        return response
    }

}
export const BASE_URL = process.env.VUE_APP_API_URL
export const SERVICE = service;

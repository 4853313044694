import Vue from 'vue'
import Router from 'vue-router'
import routes from 'vue-auto-routing'
import {createRouterLayout} from 'vue-router-layout'
import {getToken, removeToken} from "@/utils/auth";
import store from "@/store";
import {MessageBox} from "element-ui";

Vue.use(Router)

const RouterLayout = createRouterLayout(layout => {
    return import('@/layouts/' + layout + '.vue')
})

const router = new Router({
    routes: [
        {
            path: '/',
            component: RouterLayout,
            children: routes
        }
    ],
    mode: 'history'
})

router.beforeEach(async (to, from, next) => {

    const hasToken = getToken()
    const needAuth = to.meta.auth
    const needAnchor = to.meta.anchor
    if (from.name === null) {   //如果是刷新页面
        await store.dispatch('app/getSetting')
    }

    if (hasToken) {
        if (!store.state.auth.user) {     //如果有token但是没有用户
            await store.dispatch('auth/fetchProfile').catch(e => {
                store.dispatch('auth/resetToken')
                if (needAuth) {       //如果获取用户信息失败
                    return next({
                        path: '/auth/login/'
                    })
                }
            })
        }
        if(needAnchor){
            if (!store.state.my_room.myRoom) {
                await store.dispatch('my_room/getMyRoom').catch(e => {
                    return MessageBox.alert('无权访问该页面', '错误', {type: 'error'}).then(()=>{
                        router.back()
                    })
                })
            }
        }
        return next()
    } else if (needAuth || needAnchor) {
        return next({
            path: '/auth/login/'
        })
    } else {
        return next();
    }


})
export default router

import Requester from "@/reqeust/requester";
import {Apis} from "@/resources/constants";
import * as model from '@/model';
import {
    Content,
    EarningsCollect,
    EarningsRecord, Gateway,
    GiftSendRecord,
    GoldRecord,
    LiveRecord, Payment,
    RechargeOrder, RechargeRule,
    RoomItem,
    UserInfo
} from "@/model";

const request = new Requester();


/**
 * 获取全局设置
 * @returns {Promise<Setting>}
 */
export const fetchSettings = () => {
    return request.fetchAsSingle(Apis.setting, model.Setting.fromJson)
}

/**
 * 图形验证码
 * @returns {Promise<Captcha>}
 */
export const fetchGraphicalCaptcha = () => {
    return request.fetchAsSingle(Apis.sms_captcha, model.Captcha.fromJson);
}

/**
 *  发送登陆短信
 * @param mobile
 * @param uuid
 * @param inputCaptcha
 * @returns {Promise<SmsResult>}
 */
export const fetchLoginSms = (mobile, uuid = '', inputCaptcha = '') => {
    return request.postAsSingle(Apis.sms_login, model.SmsResult.fromJson, {
        mobile: mobile,
        uuid: uuid,
        captcha: inputCaptcha
    })
}

/**
 *  登录
 * @param data
 * @returns {Promise<Token>}
 */
export const login = (data = {}) => {
    return request.postAsSingle(Apis.auth_login, model.Token.fromJson, data)
}


/**
 * 获取当前用户信息
 * @returns {Promise<UserProfile>}
 */
export const fetchProfile = () => {
    return request.fetchAsSingle(Apis.profile, model.UserProfile.fromJson)
}


/**
 * 获取直播分类
 * @returns {Promise<Array<RoomCategory>>}
 */
export const fetchRoomCategoryList = () => {
    return request.fetchAsList(Apis.room_categories, model.RoomCategory.fromJson)
}


/**
 *  获取精选直播间
 * @returns {Promise<Array<RoomItem>>}
 */
export const fetchChoiceRoomList = (limit = 5) => {
    return request.fetchAsList(Apis.room_list_recommend, model.RoomItem.fromJson, {limit: limit})
}
/**
 *  获取推荐直播间
 * @returns {Promise<*>}
 */
export const fetchRecommendRoomList = (params = {limit: 20}) => {
    return request.fetchAsPage(Apis.room_list_recommend, model.RoomItem.fromJson, params)
}


/**
 *  获取推荐直播间
 * @returns {Promise<*>}
 */
export const fetchCategoryRoomList = (params = {limit: 20}) => {
    return request.fetchAsPage(Apis.room_list_category, model.RoomItem.fromJson, params)
}


/**
 * 获取推荐直播间
 * @param params
 * @returns {Promise<*>}
 */
export const fetchHotRoomList = (params = {limit: 20}) => {
    return request.fetchAsPage(Apis.room_list_hot, model.RoomItem.fromJson, params)
}

/**
 *  获取所有直播间
 * @returns {Promise<*>}
 */
export const fetchAllRoomList = (params = {limit: 20}) => {
    return request.fetchAsPage(Apis.room_all, model.RoomItem.fromJson, params)
}


/**
 * 获取直播间详情
 * @param roomNo
 * @returns {Promise<RoomDetail>}
 */
export const fetchRoomInfo = (roomNo) => {
    return request.fetchAsSingle(Apis.room_info, model.RoomDetail.fromJson, {room_no: roomNo})
}

/**
 * 我的直播间信息
 * @returns {Promise<MyRoom>}
 */
export const fetchMyRoomInfo = () => {
    return request.fetchAsSingle(Apis.my_room_info, model.MyRoom.fromJson)
}


/**
 * 获取直播间封面
 * @returns {Promise<Array<RoomThumb>>}
 */
export const fetchMyThumbList = (valid = 1) => {
    return request.fetchAsList(Apis.my_room_thumbs, model.RoomThumb.fromJson, {valid: valid})
}

/**
 * 删除直播间封面
 * @param id
 * @returns {Promise<{}>}
 */
export const deleteMyThumb = (id) => {
    return request.postAsBean(Apis.my_room_delete_thumb, {id: id})
}

/**
 * 检测流状态
 * @returns {Promise<StreamStatus>}
 */
export const checkStream = () => {
    return request.fetchAsSingle(Apis.my_room_check_stream, model.StreamStatus.fromJson)
}

/**
 *  开始直播
 * @param data
 * @returns {Promise<MyRoom>}
 */
export const startLive = (data = {}) => {
    return request.postAsSingle(Apis.my_room_start_live, model.MyRoom.fromJson, data)
}

/**
 * 停止直播
 * @returns {Promise<LiveRecord>}
 */
export const stopLive = () => {
    return request.postAsSingle(Apis.my_room_stop_live, model.LiveRecord.fromJson)
}

/**
 * 守护列表
 * @param roomNo
 * @param page
 * @returns {Promise<Object>}
 */
export const fetchGuardList = (roomNo, page = 1) => {
    return request.fetchAsPage(Apis.audience_guards, model.UserInfo.fromJson, {page: page, room_no: roomNo})
}


/**
 * 管理员列表
 * @param roomNo
 * @param page
 * @returns {Promise<Object>}
 */
export const fetchManagerList = (roomNo, page = 1) => {
    return request.fetchAsPage(Apis.audience_managers, model.UserInfo.fromJson, {room_no: roomNo, page: page})
}


/**
 * 在线用户
 * @param roomNo
 * @param page
 * @returns {Promise<Array<UserInfo>>}
 */
export const fetchOnlineList = (roomNo, page = 1) => {
    return request.fetchAsList(Apis.audience_online_users, model.UserInfo.fromJson, {room_no: roomNo, page: page})
}

/**
 * 贵宾榜单
 * @param roomNo
 * @returns {Promise<Array<UserInfo>>}
 */
export const fetchTopHonourList = (roomNo) => {
    return request.fetchAsList(Apis.audience_top_honour, model.UserInfo.fromJson, {room_no: roomNo})
}


/**
 * 本场贡献榜
 * @param roomNo
 * @param limit
 * @returns {Promise<Array<RoomContribute>>}
 */
export const fetchNowContributionList = (roomNo, limit = 10) => {
    return request.fetchAsList(Apis.audience_now_contributes, model.RoomContribute.fromJson, {
        room_no: roomNo,
        limit: limit
    })
}


/**
 * 其他时间段贡献榜
 * @param roomNo
 * @param date
 * @param limit
 * @returns {Promise<Array<RoomContribute>>}
 */
export const fetchContributionList = (roomNo, date = 'day', limit = 10) => {
    return request.fetchAsList(Apis.audience_contributes, model.RoomContribute.fromJson, {
        room_no: roomNo,
        date: date,
        limit: limit
    })
}


/**
 * 获取礼物
 * @param roomNo
 * @returns {Promise<Array<RoomGiftGroup>>}
 */
export const fetchGiftGroupList = (roomNo) => {
    return request.fetchAsList(Apis.audience_gifts, model.RoomGiftGroup.fromJson, {room_no: roomNo})
}

/**
 * 获取我的背包礼物
 * @returns {Promise<unknown>}
 */
export const fetchBagGiftList = () => {
    return request.fetchAsList(Apis.audience_bag_gifts, model.RoomGift.fromJson)
}

/**
 * 关注用户
 * @param id
 * @returns {Promise<unknown>}
 * @constructor
 */
export const followUser = (id) => {
    return request.postAsSingle(Apis.user_follow, model.FollowResult.fromJson, {id: id})
}

/**
 * 取消关注用户
 * @param id
 * @returns {Promise<unknown>}
 * @constructor
 */
export const unFollowUser = (id) => {
    return request.postAsSingle(Apis.user_unfollow, model.FollowResult.fromJson, {id: id})
}


/**
 * 申请PK
 * @param userId
 * @param type
 * @returns {Promise<{}>}
 */
export const requestPK = (userId, type) => {
    return request.postAsBean(Apis.room_pk_request, {user_id: userId, type: type})
}

/**
 * 随机PK
 * @returns {Promise<{}>}
 */
export const requestRandPK = () => {
    return request.postAsBean(Apis.room_pk_rand_request, {})
}

/**
 * 同意PK
 * @param pkId
 * @returns {Promise<{}>}
 */
export const agreePK = (pkId) => {
    return request.postAsBean(Apis.room_pk_agree, {pk_id: pkId})
}

/**
 * 拒绝PK
 * @param pkId
 * @returns {Promise<{}>}
 */
export const rejectPK = (pkId) => {
    return request.postAsBean(Apis.room_pk_reject, {pk_id: pkId})
}

/**
 * 搜索直播间
 * @param keyword
 * @param limit
 * @param page
 * @returns {Promise<unknown>}
 */
export const searchRoom = (keyword, limit = 20, page = 1) => {
    return request.fetchAsPage(Apis.room_search, RoomItem.fromJson, {keyword: keyword, limit: limit, page: page})
}

/**
 * 获取PK类型
 * @returns {Promise<unknown>}
 */
export const fetchPkTypeList = () => {
    return request.fetchAsList(Apis.room_pk_types, (list) => list)
}


/**
 * 用户粉丝
 * @returns {Promise<*>}
 * @param params
 */
export const fetchUserFansList = (params = {page: 1}) => {
    return request.fetchAsPage(Apis.user_fans, UserInfo.fromJson, params)
}

/**
 * 用户关注
 * @returns {Promise<*>}
 * @param params
 */
export const fetchUserFollowList = (params = {page: 1}) => {
    return request.fetchAsPage(Apis.user_follows, UserInfo.fromJson, params)
}

/**
 * 用户关注
 * @returns {Promise<*>}
 * @param params
 */
export const fetchMyBlackList = (params = {page: 1}) => {
    return request.fetchAsPage(Apis.user_my_blacks, UserInfo.fromJson, params)
}


/**
 * 账户日志
 * @param params
 * @returns {Promise<*>}
 */
export const fetchGoldRecord = (params = {page: 1}) => {
    return request.fetchAsPage(Apis.bill_gold_records, GoldRecord.fromJson, params)
}

/**
 * 充值订单
 * @param params
 * @returns {Promise<*>}
 */
export const fetchRechargeOrders = (params = {page: 1}) => {
    return request.fetchAsPage(Apis.bill_recharges, RechargeOrder.fromJson, params)
}

/**
 * 礼物赠送
 * @param params
 * @returns {Promise<*>}
 */
export const fetchGiftPresentRecords = (params = {page: 1}) => {
    return request.fetchAsPage(Apis.bill_gift_send_records, GiftSendRecord.fromJson, params)
}

/**
 * 收益总览
 * @returns {Promise<EarningsCollect>}
 */
export const fetchEarningsCollect = () => {
    return request.fetchAsSingle(Apis.my_room_earnings_collect, EarningsCollect.fromJson);
}


/**
 * 收益列表
 * @param params
 * @returns {Promise<*>}
 */
export const fetchEarningsList = (params = {page: 1}) => {
    return request.fetchAsPage(Apis.my_room_earnings, EarningsRecord.fromJson, params);
}


/**
 * 我的直播记录
 * @param params
 * @returns {Promise<*>}
 */
export const fetchLiveRecordList = (params) => {
    return request.fetchAsPage(Apis.my_room_live_records, LiveRecord.fromJson, params);
}


/**
 * 直播间禁言列表
 * @returns {Promise<unknown>}
 * @param roomNo
 * @param page
 */
export const fetchRoomQuiets = (roomNo, page = 1) => {
    return request.fetchAsPage(Apis.audience_quiets, UserInfo.fromJson, {room_no: roomNo, page: page});
}

/**
 * 直播间封禁列表
 * @returns {Promise<unknown>}
 * @param roomNo
 * @param page
 */
export const fetchRoomLocks = (roomNo, page = 1) => {
    return request.fetchAsPage(Apis.audience_locks, UserInfo.fromJson, {room_no: roomNo, page: page});
}


/**
 * 添加房管
 * @param roomNo
 * @param userId
 * @returns {Promise<{}>}
 */
export const addRoomManager = (roomNo, userId) => {
    return request.postAsBean(Apis.room_manage_add_manager, {room_no: roomNo, user_id: userId})
}


/**
 * 删除房管
 * @param roomNo
 * @param userId
 * @returns {Promise<{}>}
 */
export const removeRoomManager = (roomNo, userId) => {
    return request.postAsBean(Apis.room_manage_remove_manager, {room_no: roomNo, user_id: userId})
}


/**
 * 添加禁言
 * @param roomNo
 * @param userId
 * @param minutes
 * @returns {Promise<{}>}
 */
export const addRoomQuiet = (roomNo, userId, minutes = 5) => {
    return request.postAsBean(Apis.room_manage_add_quiet, {room_no: roomNo, user_id: userId, minutes: minutes})
}


/**
 * 删除禁言
 * @param roomNo
 * @param userId
 * @returns {Promise<{}>}
 */
export const removeRoomQuiet = (roomNo, userId) => {
    return request.postAsBean(Apis.room_manage_remove_quiet, {room_no: roomNo, user_id: userId})
}

/**
 * 添加封禁
 * @param roomNo
 * @param userId
 * @param minutes
 * @returns {Promise<{}>}
 */
export const addRoomLock = (roomNo, userId, minutes = 5) => {
    return request.postAsBean(Apis.room_manage_add_lock, {room_no: roomNo, user_id: userId, minutes: minutes})
}


/**
 * 删除封禁
 * @param roomNo
 * @param userId
 * @returns {Promise<{}>}
 */
export const removeRoomLock = (roomNo, userId) => {
    return request.postAsBean(Apis.room_manage_remove_lock, {room_no: roomNo, user_id: userId})
}

/**
 * 搜索用户
 * @param params
 * @returns {Promise<*>}
 */
export const searchUser = (params = {keyword: '', page: 1}) => {
    return request.fetchAsPage(Apis.user_search, UserInfo.fromJson, params)
}


/**
 * 获取充值规则
 * @returns {Promise<RechargeRule>}
 */
export const fetchRechargeRules = () => {
    return request.fetchAsSingle(Apis.recharge_rules, RechargeRule.fromJson)
}

/**
 * 获取支付网关列表
 * @returns {Promise<unknown>}
 */
export const fetchRechargeGatewayList = () => {
    return request.fetchAsList(Apis.recharge_gateways, Gateway.fromJson)
}

/**
 * 创建充值订单
 * @param data
 * @returns {Promise<unknown>}
 */
export const rechargeCreateOrder = (data) => {
    return request.postAsSingle(Apis.recharge_create_order, Payment.fromJson, data)
}


/**
 * 查询订单状态
 * @param orderNo
 * @returns {Promise<RechargeOrder>}
 */
export const fetchOrderInfo = (orderNo) => {
    return request.fetchAsSingle(Apis.recharge_order_info, RechargeOrder.fromJson, {order_no: orderNo})
}

/**
 * 获取单页内容
 * @param type
 * @returns {Promise<Content>}
 */
export const fetchContent = (type) => {
    return request.fetchAsSingle(`${Apis.content}${type}`, Content.fromJson)
}

/**
 * 最后一次直播记录
 * @returns {Promise<LiveRecord>}
 */
export const fetchLastLiveRecord = () => {
    return request.fetchAsSingle(Apis.my_room_last_live_record, LiveRecord.fromJson)
}

/**
 * 查询用户信息
 * @param id
 * @param roomNo
 * @returns {Promise<UserInfo>}
 */
export const fetchUserInfo = (id, roomNo = null) => {
    return request.fetchAsSingle(Apis.user_info, UserInfo.fromJson, {id: id, room_no: roomNo})
}


/**
 * 添加黑名单
 * @param id
 * @returns {Promise<{}>}
 */
export const addUserBlack = (id) => {
    return request.postAsBean(Apis.user_black, {id: id})
}

/**
 * 取消黑名单
 * @param id
 * @returns {Promise<{}>}
 */
export const cancelUserBlack = (id) => {
    return request.postAsBean(Apis.user_cancel_black, {id: id})
}


/**
 * 获取我的麦时
 * @returns {Promise<*>}
 */
export const fetchLiveDuration = (params = {limit: 20}) => {
    return request.fetchAsPage(Apis.my_room_live_durations, model.LiveDuration.fromJson, params)
}

/**
 * 获取我的麦时
 * @returns {Promise<*>}
 */
export const fetchLiveDurationCollect = () => {
    return request.fetchAsSingle(Apis.my_room_live_duration_collect, model.LiveDurationCollect.fromJson)
}

/**
 * 上传日志
 * @returns {Promise<*>}
 */
export const postLog = (data) => {
    return request.postAsBean(Apis.post_log, data)
}

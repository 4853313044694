import {getToken, removeToken, setToken} from "@/utils/auth";
import Vue from "vue";
import {fetchProfile, login} from "@/reqeust/api";

const state = {
    token: getToken(),
    user: null
}

const mutations = {
    SET_TOKEN: (state, token) => {
        Vue.set(state, 'token', token)
    },
    SET_USER: (state, user) => {
        Vue.set(state, 'user', user)
    }
}

const actions = {

    /**
     *  登陆
     * @param commit
     * @param data
     * @returns {Promise<null>}
     */
    login({commit}, data) {
        return new Promise((resolve, reject) => {
            login(data).then(result => {
                commit('SET_TOKEN', result.access_token)
                setToken(result.access_token);
                resolve();
            }).catch(error => reject(error))
        })
    },
    fetchProfile({commit, state}) {
        return new Promise((resolve, reject) => {
            fetchProfile().then(data => {
                if (!data) {
                    reject('验证失败，请重新登录')
                }
                commit('SET_USER', data)
                resolve(data)
            })
        })
    },
    logout({commit, state, dispatch}) {
        return new Promise((resolve, reject) => {
            commit('SET_TOKEN', '')
            removeToken()
            commit('SET_USER', null)
            resolve()
        })
    },
    resetToken({commit}) {
        return new Promise(resolve => {
            commit('SET_TOKEN', '')
            removeToken()
            resolve()
        })
    },
    updateUser({commit}, userObj) {
        let user = Object.assign({}, state.user, userObj)
        commit('SET_USER', user)
    }
}


export default {
    namespaced: true,
    state,
    mutations,
    actions
}


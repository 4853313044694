<template>
    <div id="app">
        <router-view/>
        <el-dialog title="登录" :visible.sync="showLogin" width="500px" v-if="showLogin">
            <login @success="loginSuccess"/>
        </el-dialog>
    </div>

</template>

<script>

    import login from "@/components/layout/auth/login";

    export default {
        provide() {
            return {
                doLogin: this.doLogin,
            }
        },
        data() {
            return {
                showLogin: false,
                loginCallback: null
            }
        },
        async beforeCreate() {


        },
        created() {

        },
        components: {
            login
        },
        methods: {
            loginSuccess() {
                this.loginCallback && this.loginCallback();
            },
            doLogin() {
                this.showLogin = true
                return new Promise((resolve) => {
                    this.loginCallback = resolve
                })
            },

        }
    }
</script>


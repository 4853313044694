<template>
    <el-form :model="formData" ref="loginForm">
        <el-form-item label="手机号码" prop="mobile" :rules="rules['mobile']">
            <el-input v-model="formData.mobile"/>
        </el-form-item>
        <el-form-item label="密码" v-if="formData.type === 1" prop="password" :rules="rules['password']">
            <el-input type="password" v-model="formData.password"/>
        </el-form-item>
        <el-form-item label="验证码" prop="code" :rules="rules['code']" v-if="formData.type === 0">
            <div class="send-code">
                <el-input placeholder="验证码" v-model="formData.code" style="margin-right: 10px"/>
                <countdown :time="smsInterval * 1000" v-if="smsInterval > 0" @end="()=>{this.smsInterval = 0}">
                    <el-button slot-scope="props" disabled>{{ props.seconds}}后重试
                    </el-button>
                </countdown>
                <el-button v-else @click="handleSendSms">发送验证码</el-button>
            </div>
        </el-form-item>
        <!--                <el-form-item label="图形验证码" v-if="formData.type === 0" :rules="[ {required: true, message: '请输入图形验证码', trigger: 'blur'}]">-->
        <!--                   <div class="captcha">-->
        <!--                       <el-input v-model="smsCode" style="margin-right: 10px" />-->
        <!--                       <img v-if="captcha" :src="captcha.base64" alt="验证码" @click="fetchGraphicalCaptcha" />-->
        <!--                   </div>-->
        <!--                </el-form-item>-->
        <div style="text-align: right">
            <el-button type="text" v-if="formData.type === 1" @click="formData.type = 0">使用验证码登录</el-button>
            <el-button type="text" v-if="formData.type === 0" @click="formData.type = 1">使用密码登录</el-button>
        </div>

        <el-form-item>
            <el-button type="primary" style="width: 100%" @click="handleLogin" :loading="loading">登录</el-button>
        </el-form-item>
    </el-form>
</template>

<script>
    import {fetchGraphicalCaptcha, fetchLoginSms} from "@/reqeust/api";
    import Countdown from '@chenfengyuan/vue-countdown'

    export default {
        components: {Countdown},
        computed: {
            rules: function () {
                return {
                    mobile: [{required: true, message: '请输入手机号码', trigger: 'blur'}],
                    password: this.formData.type === 1 ? [
                        {required: true, message: '请输入登录密码', trigger: 'blur'},
                    ] : [],

                    code: this.formData.type === 0 ? [
                        {required: true, message: '请输入验证码', trigger: 'blur'},
                    ] : [],
                }
            }
        },
        data() {
            return {
                loading:false,
                formData: {
                    type: 1,    //0为验证码登录
                    mobile: '',
                    password: '',
                    code: '',

                },
                /**
                 * @type Captcha
                 */
                captcha: null,
                smsCode: null,
                smsInterval: 0,
            }
        },
        created() {
            //this.fetchGraphicalCaptcha()
        },
        methods: {
            fetchGraphicalCaptcha() {
                fetchGraphicalCaptcha().then(data => this.captcha = data)
            },
            handleSendSms() {
                if (!this.formData.mobile) {
                    return this.$message.error('请输入手机号码')
                }
                fetchLoginSms(this.formData.mobile).then(data => {
                    this.smsInterval = data.interval
                    if (data.code) {
                        this.formData.code = data.code
                    }
                })
            },
            handleLogin() {
                this.$refs.loginForm.validate(valid => {
                    if (valid) {
                        this.loading = true
                        this.$store.dispatch('auth/login', this.formData).then(() => {
                            this.$store.dispatch('auth/fetchProfile')
                            this.$emit('success')
                            this.loading = false
                        }).catch(e => {
                            this.loading = false
                        })
                    }
                });

            }
        }
    }
</script>

<style scoped lang="scss">
    .send-code {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-basis: 48%;
    }

    .captcha {
        width: 100%;
        display: flex;
        flex-basis: 48%;
        justify-content: space-between;
    }
</style>


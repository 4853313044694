
///主播申请信息对象,用于用户查询自己的主播申请情况
export class AnchorApply {
    id 
    user_id 
    real_name 
    cert_no 
    cert_image_1 
    cert_image_2 
    cert_image_hand 
    experience 
    status 
    created_at 
    updated_at 
    reject_reason  //未通过原因 
    contact  //联系方式 
    bank_id 
    bank_name 
    bank_account 
    bank_open 
    /**
     * @param json
     * @returns AnchorApply
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new AnchorApply();
        bean.id = json['id'];
        bean.user_id = json['user_id'];
        bean.real_name = json['real_name'];
        bean.cert_no = json['cert_no'];
        bean.cert_image_1 = json['cert_image_1'];
        bean.cert_image_2 = json['cert_image_2'];
        bean.cert_image_hand = json['cert_image_hand'];
        bean.experience = json['experience'];
        bean.status = json['status'];
        bean.created_at = json['created_at'];
        bean.updated_at = json['updated_at'];
        bean.reject_reason = json['reject_reason'];
        bean.contact = json['contact'];
        bean.bank_id = json['bank_id'];
        bean.bank_name = json['bank_name'];
        bean.bank_account = json['bank_account'];
        bean.bank_open = json['bank_open'];
        return bean;
         }

}

///主播对象,一般附属在其他对象上
export class AnchorInfo {
    id 
    nickname 
    avatar_big 
    is_followed 
    is_living 
    avatar_small 
    level 
    level_info 
    follow_num 
    fans_num 
    /**
     * @param json
     * @returns AnchorInfo
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new AnchorInfo();
        bean.id = json['id'];
        bean.nickname = json['nickname'];
        bean.avatar_big = json['avatar_big'];
        bean.is_followed = json['is_followed'];
        bean.is_living = json['is_living'];
        bean.avatar_small = json['avatar_small'];
        bean.level = json['level'];
        bean.level_info = json['level_info'] != null ? UserLevel.fromJson(json['level_info']) : null;
        bean.follow_num = json['follow_num'];
        bean.fans_num = json['fans_num'];
        return bean;
         }

}

///主播排行的item对象,用户排行榜
export class AnchorRank {
    room_id 
    total_amount 
    anchor 
    room 
    /**
     * @param json
     * @returns AnchorRank
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new AnchorRank();
        bean.room_id = json['room_id'];
        bean.total_amount = json['total_amount'];
        bean.anchor = json['anchor'] != null ? AnchorInfo.fromJson(json['anchor']) : null;
        bean.room = json['room'] != null ? RoomItem.fromJson(json['room']) : null;
        return bean;
         }

}

///APP检查更新模型
export class AppUpgrade {
    version  //最新版本号 需要根据是否存在version字段来判断是否有版本更新 
    content  //升级描述内容 
    platform  //0安卓1ios 
    url  //升级的URL或者appid 
    url_type  //0跳转到浏览器打开网址 1 (如果是安卓,直接调用系统下载安装,如果是ios跳转到appstore) 
    created_at  //发布时间 
    force  //是否强制升级 
    /**
     * @param json
     * @returns AppUpgrade
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new AppUpgrade();
        bean.version = json['version'];
        bean.content = json['content'];
        bean.platform = json['platform'];
        bean.url = json['url'];
        bean.url_type = json['url_type'];
        bean.created_at = json['created_at'];
        bean.force = json['force'];
        return bean;
         }

}

///观众模型,表示用户和直播间的关系,和相关数据
export class Audience {
    user_id 
    room_no 
    is_guard  //是否为守护 
    guard_amount  //守护值 
    consume  //直播间总消费 
    contribution  //直播间总贡献 
    intimacy  //直播间亲密度 
    is_locked  //是否被锁定(不允许进入直播间) 
    is_quiet  //是否被禁言 
    is_manager  //是否为房管 
    nobility  //贵族等级(0/1/2/3)待定 
    is_club_fans  //是否为粉丝团成员 
    manager_at 
    quieted_at 
    quiet_expire 
    locked_at 
    locked_expire 
    guard_expire 
    /**
     * @param json
     * @returns Audience
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Audience();
        bean.user_id = json['user_id'];
        bean.room_no = json['room_no'];
        bean.is_guard = json['is_guard'];
        bean.guard_amount = json['guard_amount'];
        bean.consume = json['consume'];
        bean.contribution = json['contribution'];
        bean.intimacy = json['intimacy'];
        bean.is_locked = json['is_locked'];
        bean.is_quiet = json['is_quiet'];
        bean.is_manager = json['is_manager'];
        bean.nobility = json['nobility'];
        bean.is_club_fans = json['is_club_fans'];
        bean.manager_at = json['manager_at'];
        bean.quieted_at = json['quieted_at'];
        bean.quiet_expire = json['quiet_expire'];
        bean.locked_at = json['locked_at'];
        bean.locked_expire = json['locked_expire'];
        bean.guard_expire = json['guard_expire'];
        return bean;
         }

}

///用户角标对象,附加在用户的角标列表上
export class Badge {
    badge 
    text 
    /**
     * @param json
     * @returns Badge
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Badge();
        bean.badge = json['badge'];
        bean.text = json['text'];
        return bean;
         }

}

///获取系统支持的银行列表,用于添加账户-&gt;选择银行
export class Bank {
    id 
    name 
    image 
    code 
    /**
     * @param json
     * @returns Bank
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Bank();
        bean.id = json['id'];
        bean.name = json['name'];
        bean.image = json['image'];
        bean.code = json['code'];
        return bean;
         }

}

///发送短信时候请求的的图形验证码
export class Captcha {
    base64 
    uuid 
    /**
     * @param json
     * @returns Captcha
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Captcha();
        bean.base64 = json['base64'];
        bean.uuid = json['uuid'];
        return bean;
         }

}

///粉丝团对象
export class Club {
    id 
    name 
    intimate  //总的贡献值 
    month_intimate  //本月贡献值 
    week_intimate  //本周贡献值 
    last_week_intimate  //上周贡献值 
    last_month_intimate  //上月贡献值 
    level 
    announcement  //公告 
    created_at 
    updated_at 
    users_count  //总用户数 
    anchor  //对应的主播 
    /**
     * @param json
     * @returns Club
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Club();
        bean.id = json['id'];
        bean.name = json['name'];
        bean.intimate = json['intimate'];
        bean.month_intimate = json['month_intimate'];
        bean.week_intimate = json['week_intimate'];
        bean.last_week_intimate = json['last_week_intimate'];
        bean.last_month_intimate = json['last_month_intimate'];
        bean.level = json['level'];
        bean.announcement = json['announcement'];
        bean.created_at = json['created_at'];
        bean.updated_at = json['updated_at'];
        bean.users_count = json['users_count'];
        bean.anchor = json['anchor'] != null ? AnchorInfo.fromJson(json['anchor']) : null;
        return bean;
         }

}

///粉丝团成员Item对象
export class ClubUser {
    id 
    avatar_small 
    nickname 
    fans_num 
    intimate 
    created_at 
    /**
     * @param json
     * @returns ClubUser
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new ClubUser();
        bean.id = json['id'];
        bean.avatar_small = json['avatar_small'];
        bean.nickname = json['nickname'];
        bean.fans_num = json['fans_num'];
        bean.intimate = json['intimate'];
        bean.created_at = json['created_at'];
        return bean;
         }

}

///单页内容
export class Content {
    id 
    title 
    name 
    content 
    created_at 
    updated_at 
    /**
     * @param json
     * @returns Content
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Content();
        bean.id = json['id'];
        bean.title = json['title'];
        bean.name = json['name'];
        bean.content = json['content'];
        bean.created_at = json['created_at'];
        bean.updated_at = json['updated_at'];
        return bean;
         }

}

///收益总览
export class EarningsCollect {
    today_sum 
    month_sum 
    yesterday_sum 
    all_sum 
    /**
     * @param json
     * @returns EarningsCollect
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new EarningsCollect();
        bean.today_sum = json['today_sum'];
        bean.month_sum = json['month_sum'];
        bean.yesterday_sum = json['yesterday_sum'];
        bean.all_sum = json['all_sum'];
        return bean;
         }

}

///收益记录
export class EarningsRecord {
    id 
    room_id 
    earnings 
    gold 
    from_user_id 
    remark 
    created_at 
    /**
     * @param json
     * @returns EarningsRecord
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new EarningsRecord();
        bean.id = json['id'];
        bean.room_id = json['room_id'];
        bean.earnings = json['earnings'];
        bean.gold = json['gold'];
        bean.from_user_id = json['from_user_id'];
        bean.remark = json['remark'];
        bean.created_at = json['created_at'];
        return bean;
         }

}

///动效模型
export class Effect {
    animation  //svga动画地址(可能为null或者为空字符串) 
    audio  //声音地址(可能为null或者为空字符串) 
    /**
     * @param json
     * @returns Effect
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Effect();
        bean.animation = json['animation'];
        bean.audio = json['audio'];
        return bean;
         }

}

///家族详情模型,包含完整信息
export class FamilyDetail {
    id 
    name  //名称 
    avatar  //头像 
    badge  //角标 
    rank  //排行 
    introduction  //描述 
    contribution  //总贡献值 
    status  //状态 
    can_join  //是否能加入 
    can_manage  //是否能管理 
    leader  //族长 
    anchors_count  //主播总数 
    users_count  //用户总数 
    elites_count  //精英总数 
    background  //背景图片 
    /**
     * @param json
     * @returns FamilyDetail
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new FamilyDetail();
        bean.id = json['id'];
        bean.name = json['name'];
        bean.avatar = json['avatar'];
        bean.badge = json['badge'];
        bean.rank = json['rank'];
        bean.introduction = json['introduction'];
        bean.contribution = json['contribution'];
        bean.status = json['status'];
        bean.can_join = json['can_join'];
        bean.can_manage = json['can_manage'];
        bean.leader = json['leader'] != null ? UserInfo.fromJson(json['leader']) : null;
        bean.anchors_count = json['anchors_count'];
        bean.users_count = json['users_count'];
        bean.elites_count = json['elites_count'];
        bean.background = json['background'];
        return bean;
         }

}

///家族列表信息,用于列表展示,和搜索
export class FamilyItem {
    id 
    name 
    avatar 
    badge 
    rank 
    introduction 
    contribution 
    status 
    anchors_count 
    users_count 
    leader 
    /**
     * @param json
     * @returns FamilyItem
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new FamilyItem();
        bean.id = json['id'];
        bean.name = json['name'];
        bean.avatar = json['avatar'];
        bean.badge = json['badge'];
        bean.rank = json['rank'];
        bean.introduction = json['introduction'];
        bean.contribution = json['contribution'];
        bean.status = json['status'];
        bean.anchors_count = json['anchors_count'];
        bean.users_count = json['users_count'];
        bean.leader = json['leader'] != null ? UserInfo.fromJson(json['leader']) : null;
        return bean;
         }

}

///家族排行的item对象,用户排行榜
export class FamilyRank {
    family_id 
    total_amount 
    family 
    /**
     * @param json
     * @returns FamilyRank
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new FamilyRank();
        bean.family_id = json['family_id'];
        bean.total_amount = json['total_amount'];
        bean.family = json['family'] != null ? FamilyItem.fromJson(json['family']) : null;
        return bean;
         }

}

///家族成员对象,用于家族成员列表
export class FamilyUser {
    id 
    avatar_small 
    nickname 
    gender 
    fans_num 
    gold_earnings_total 
    family_role 
    family_contribution 
    level_info 
    room 
    /**
     * @param json
     * @returns FamilyUser
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new FamilyUser();
        bean.id = json['id'];
        bean.avatar_small = json['avatar_small'];
        bean.nickname = json['nickname'];
        bean.gender = json['gender'];
        bean.fans_num = json['fans_num'];
        bean.gold_earnings_total = json['gold_earnings_total'];
        bean.family_role = json['family_role'];
        bean.family_contribution = json['family_contribution'];
        bean.level_info = json['level_info'] != null ? UserLevel.fromJson(json['level_info']) : null;
        bean.room = json['room'] != null ? UserRoom.fromJson(json['room']) : null;
        return bean;
         }

}

///关注和取消关注接口返回对象
export class FollowResult {
    follow_num  //我当前的关注数量 
    follow_user  //当前关注/取消关注的用户 
    /**
     * @param json
     * @returns FollowResult
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new FollowResult();
        bean.follow_num = json['follow_num'];
        bean.follow_user = json['follow_user'] != null ? UserInfo.fromJson(json['follow_user']) : null;
        return bean;
         }

}

///支付网关
export class Gateway {
    gateway 
    type 
    name 
    /**
     * @param json
     * @returns Gateway
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Gateway();
        bean.gateway = json['gateway'];
        bean.type = json['type'];
        bean.name = json['name'];
        return bean;
         }

}

///礼物分类对象
export class GiftCategory {
    id 
    name 
    /**
     * @param json
     * @returns GiftCategory
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new GiftCategory();
        bean.id = json['id'];
        bean.name = json['name'];
        return bean;
         }

}

///礼物赠送记录
export class GiftSendRecord {
    id 
    user_id 
    room_id 
    total_price 
    num 
    created_at 
    gift 
    /**
     * @param json
     * @returns GiftSendRecord
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new GiftSendRecord();
        bean.id = json['id'];
        bean.user_id = json['user_id'];
        bean.room_id = json['room_id'];
        bean.total_price = json['total_price'];
        bean.num = json['num'];
        bean.created_at = json['created_at'];
        bean.gift = json['gift'] != null ? SimpleGift.fromJson(json['gift']) : null;
        return bean;
         }

}

///用户的金币变化记录日志(用于个人中心)
export class GoldRecord {
    id 
    type 
    amount 
    remark 
    created_at 
    /**
     * @param json
     * @returns GoldRecord
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new GoldRecord();
        bean.id = json['id'];
        bean.type = json['type'];
        bean.amount = json['amount'];
        bean.remark = json['remark'];
        bean.created_at = json['created_at'];
        return bean;
         }

}

///守护价格
export class GuardPrice {
    id 
    name 
    long 
    price 
    line_price 
    guard_amount  //增加守护值 
    /**
     * @param json
     * @returns GuardPrice
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new GuardPrice();
        bean.id = json['id'];
        bean.name = json['name'];
        bean.long = json['long'];
        bean.price = json['price'];
        bean.line_price = json['line_price'];
        bean.guard_amount = json['guard_amount'];
        return bean;
         }

}

///自定义消息合并模型
export class IMCustomMessage {
    title 
    thumb 
    content 
    room_no 
    url 
    /**
     * @param json
     * @returns IMCustomMessage
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new IMCustomMessage();
        bean.title = json['title'];
        bean.thumb = json['thumb'];
        bean.content = json['content'];
        bean.room_no = json['room_no'];
        bean.url = json['url'];
        return bean;
         }

}

///开播通知消息model(从自定义消息的payload.data转化)
export class IMLiveMessage {
    title  //消息标题 
    thumb  //图片 
    content  //内容 
    room_no  //点击跳转到直播间号 
    /**
     * @param json
     * @returns IMLiveMessage
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new IMLiveMessage();
        bean.title = json['title'];
        bean.thumb = json['thumb'];
        bean.content = json['content'];
        bean.room_no = json['room_no'];
        return bean;
         }

}

///IM官方消息(从自定义消息payload.data转化)
export class IMOfficialMessage {
    title 
    thumb 
    content 
    url  //点击跳转URL 
    /**
     * @param json
     * @returns IMOfficialMessage
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new IMOfficialMessage();
        bean.title = json['title'];
        bean.thumb = json['thumb'];
        bean.content = json['content'];
        bean.url = json['url'];
        return bean;
         }

}

///IM系统消息(从自定义消息payload.data转化)
export class IMSystemMessage {
    title 
    thumb 
    content 
    url  //点击跳转URL 
    /**
     * @param json
     * @returns IMSystemMessage
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new IMSystemMessage();
        bean.title = json['title'];
        bean.thumb = json['thumb'];
        bean.content = json['content'];
        bean.url = json['url'];
        return bean;
         }

}

///麦时
export class LiveDuration {
    user_id 
    room_id 
    date 
    duration 
    /**
     * @param json
     * @returns LiveDuration
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new LiveDuration();
        bean.user_id = json['user_id'];
        bean.room_id = json['room_id'];
        bean.date = json['date'];
        bean.duration = json['duration'];
        return bean;
         }

}

///麦时统计
export class LiveDurationCollect {
    today 
    yesterday 
    week 
    last_week 
    month 
    last_month 
    /**
     * @param json
     * @returns LiveDurationCollect
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new LiveDurationCollect();
        bean.today = json['today'];
        bean.yesterday = json['yesterday'];
        bean.week = json['week'];
        bean.last_week = json['last_week'];
        bean.month = json['month'];
        bean.last_month = json['last_month'];
        return bean;
         }

}

///直播记录item,用于查询我的直播记录
export class LiveRecord {
    id 
    room_no 
    duration 
    started_at 
    ended_at 
    earnings 
    audience_num 
    add_fans 
    add_exp 
    room 
    /**
     * @param json
     * @returns LiveRecord
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new LiveRecord();
        bean.id = json['id'];
        bean.room_no = json['room_no'];
        bean.duration = json['duration'];
        bean.started_at = json['started_at'];
        bean.ended_at = json['ended_at'];
        bean.earnings = json['earnings'];
        bean.audience_num = json['audience_num'];
        bean.add_fans = json['add_fans'];
        bean.add_exp = json['add_exp'];
        bean.room = json['room'] != null ? RoomItem.fromJson(json['room']) : null;
        return bean;
         }

}

///游戏详情
export class LotteryGameInfo {
    id 
    image 
    url 
    code 
    price 
    remark 
    prize 
    gold 
    name 
    /**
     * @param json
     * @returns LotteryGameInfo
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new LotteryGameInfo();
        bean.id = json['id'];
        bean.image = json['image'];
        bean.url = json['url'];
        bean.code = json['code'];
        bean.price = json['price'];
        bean.remark = json['remark'];
        if (json['prize'] != null) {
                        bean.prize = json['prize'].map(item => LotteryGamePrizeRate.fromJson(item));
                    }
        bean.gold = json['gold'];
        bean.name = json['name'];
        return bean;
         }

}

///抽奖操作返回的奖品信息
export class LotteryGamePrize {
    type  //0金币 1 礼物 2 未中奖 
    image 
    amount 
    remark 
    gold  //当前用户金币 
    /**
     * @param json
     * @returns LotteryGamePrize
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new LotteryGamePrize();
        bean.type = json['type'];
        bean.image = json['image'];
        bean.amount = json['amount'];
        bean.remark = json['remark'];
        bean.gold = json['gold'];
        return bean;
         }

}

///游戏奖品和中奖几率
export class LotteryGamePrizeRate {
    rate 
    type 
    image 
    amount 
    remark 
    gift_id 
    /**
     * @param json
     * @returns LotteryGamePrizeRate
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new LotteryGamePrizeRate();
        bean.rate = json['rate'];
        bean.type = json['type'];
        bean.image = json['image'];
        bean.amount = json['amount'];
        bean.remark = json['remark'];
        bean.gift_id = json['gift_id'];
        return bean;
         }

}

///勋章对象
export class Medal {
    id 
    name 
    image 
    remark 
    is_possess  //是否已经拥有 
    /**
     * @param json
     * @returns Medal
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Medal();
        bean.id = json['id'];
        bean.name = json['name'];
        bean.image = json['image'];
        bean.remark = json['remark'];
        bean.is_possess = json['is_possess'];
        return bean;
         }

}

///音乐详情模型(暂定)
export class MusicInfo {
    id 
    name 
    play_url 
    /**
     * @param json
     * @returns MusicInfo
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new MusicInfo();
        bean.id = json['id'];
        bean.name = json['name'];
        bean.play_url = json['play_url'];
        return bean;
         }

}

///音乐item(暂定)
export class MusicItem {
    id 
    name 
    topic 
    singer 
    size 
    extname 
    duration 
    /**
     * @param json
     * @returns MusicItem
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new MusicItem();
        bean.id = json['id'];
        bean.name = json['name'];
        bean.topic = json['topic'];
        bean.singer = json['singer'];
        bean.size = json['size'];
        bean.extname = json['extname'];
        bean.duration = json['duration'];
        return bean;
         }

}

///我的直播间,用于主播查询
export class MyRoom {
    id 
    room_no 
    name 
    thumb 
    category 
    introduction 
    stream_name 
    is_living 
    level 
    exp 
    audience_num 
    guard_num 
    manager_num 
    total_duration 
    popularity 
    stream_push_url 
    level_info 
    is_pk 
    thumb_num 
    record_num 
    url  //PC端网址 
    now_contribution 
    stream_rtc_push_url 
    /**
     * @param json
     * @returns MyRoom
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new MyRoom();
        bean.id = json['id'];
        bean.room_no = json['room_no'];
        bean.name = json['name'];
        bean.thumb = json['thumb'];
        bean.category = json['category'] != null ? RoomCategory.fromJson(json['category']) : null;
        bean.introduction = json['introduction'];
        bean.stream_name = json['stream_name'];
        bean.is_living = json['is_living'];
        bean.level = json['level'];
        bean.exp = json['exp'];
        bean.audience_num = json['audience_num'];
        bean.guard_num = json['guard_num'];
        bean.manager_num = json['manager_num'];
        bean.total_duration = json['total_duration'];
        bean.popularity = json['popularity'];
        bean.stream_push_url = json['stream_push_url'];
        bean.level_info = json['level_info'] != null ? RoomLevel.fromJson(json['level_info']) : null;
        bean.is_pk = json['is_pk'];
        bean.thumb_num = json['thumb_num'];
        bean.record_num = json['record_num'];
        bean.url = json['url'];
        bean.now_contribution = json['now_contribution'];
        bean.stream_rtc_push_url = json['stream_rtc_push_url'];
        return bean;
         }

}

///上一个下一个直播间的就简单model
export class NextRoom {
    name 
    room_no 
    thumb 
    /**
     * @param json
     * @returns NextRoom
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new NextRoom();
        bean.name = json['name'];
        bean.room_no = json['room_no'];
        bean.thumb = json['thumb'];
        return bean;
         }

}

///单个靓号Item对象
export class NiceNumber {
    id 
    number  //号码 
    badge  //徽章图片 
    price  //价格 
    line_price  //划线价(原价:展示用) 
    length  //位数长度 
    /**
     * @param json
     * @returns NiceNumber
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new NiceNumber();
        bean.id = json['id'];
        bean.number = json['number'];
        bean.badge = json['badge'];
        bean.price = json['price'];
        bean.line_price = json['line_price'];
        bean.length = json['length'];
        return bean;
         }

}

///靓号分组列表,用于商城给靓号进行长队分组
export class NiceNumberList {
    length  //4 
    list 
    /**
     * @param json
     * @returns NiceNumberList
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new NiceNumberList();
        bean.length = json['length'];
        if (json['list'] != null) {
                        bean.list = json['list'].map(item => NiceNumber.fromJson(item));
                    }
        return bean;
         }

}

///三方账号绑定状态
export class OAuthBind {
    qq 
    wechat 
    weibo 
    apple 
    /**
     * @param json
     * @returns OAuthBind
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new OAuthBind();
        bean.qq = json['qq'];
        bean.wechat = json['wechat'];
        bean.weibo = json['weibo'];
        bean.apple = json['apple'];
        return bean;
         }

}

///请求支付接口返回的数据对象
export class Payment {
    order_no 
    gateway  //支付方式 
    type  //数据类型(可能为 string/json/qrcode/url等),根据支付方式不同,数据不同 
    content  //需要根据type,手动转化数据类型 
    /**
     * @param json
     * @returns Payment
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Payment();
        bean.order_no = json['order_no'];
        bean.gateway = json['gateway'];
        bean.type = json['type'];
        bean.content = json['content'];
        return bean;
         }

}

///苹果内购Item //废弃,统一用RechargeRule
export class RechargeInApp {
    amount 
    price 
    remark 
    product_id 
    /**
     * @param json
     * @returns RechargeInApp
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RechargeInApp();
        bean.amount = json['amount'];
        bean.price = json['price'];
        bean.remark = json['remark'];
        bean.product_id = json['product_id'];
        return bean;
         }

}

///充值订单
export class RechargeOrder {
    order_no 
    status 
    price 
    amount 
    remark 
    created_at 
    gateway 
    paid_at 
    /**
     * @param json
     * @returns RechargeOrder
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RechargeOrder();
        bean.order_no = json['order_no'];
        bean.status = json['status'];
        bean.price = json['price'];
        bean.amount = json['amount'];
        bean.remark = json['remark'];
        bean.created_at = json['created_at'];
        bean.gateway = json['gateway'];
        bean.paid_at = json['paid_at'];
        return bean;
         }

}

///聚合充值规则
export class RechargeRule {
    recharge_rates 
    min_recharge 
    gateways 
    rules 
    /**
     * @param json
     * @returns RechargeRule
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RechargeRule();
        bean.recharge_rates = json['recharge_rates'];
        bean.min_recharge = json['min_recharge'];
        if (json['gateways'] != null) {
                        bean.gateways = json['gateways'].map(item => Gateway.fromJson(item));
                    }
        if (json['rules'] != null) {
                        bean.rules = json['rules'].map(item => RechargeRuleItem.fromJson(item));
                    }
        return bean;
         }

}

///
export class RechargeRuleAward {
    id 
    days 
    type 
    image 
    remark 
    /**
     * @param json
     * @returns RechargeRuleAward
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RechargeRuleAward();
        bean.id = json['id'];
        bean.days = json['days'];
        bean.type = json['type'];
        bean.image = json['image'];
        bean.remark = json['remark'];
        return bean;
         }

}

///每一条充值规则
export class RechargeRuleItem {
    price 
    amount 
    remark 
    type 
    award 
    product_id 
    /**
     * @param json
     * @returns RechargeRuleItem
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RechargeRuleItem();
        bean.price = json['price'];
        bean.amount = json['amount'];
        bean.remark = json['remark'];
        bean.type = json['type'];
        if (json['award'] != null) {
                        bean.award = json['award'].map(item => RechargeRuleAward.fromJson(item));
                    }
        bean.product_id = json['product_id'];
        return bean;
         }

}

///红包模型 redEnvelopeSend、redEnvelopeOver消息直接返回此模型
export class RedEnvelope {
    id  //红包ID 
    amount  //红包总金额 
    total  //红包总人数 
    status  //状态 0 未开抢 1 抢红包中 2 已结束 
    created_at  //创建时间 
    started_at  //开始时间 
    ended_at  //结束时间 
    user  //发红包的用户 
    room  //所在直播间 
    /**
     * @param json
     * @returns RedEnvelope
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RedEnvelope();
        bean.id = json['id'];
        bean.amount = json['amount'];
        bean.total = json['total'];
        bean.status = json['status'];
        bean.created_at = json['created_at'];
        bean.started_at = json['started_at'];
        bean.ended_at = json['ended_at'];
        bean.user = json['user'] != null ? UserInfo.fromJson(json['user']) : null;
        bean.room = json['room'] != null ? RoomItem.fromJson(json['room']) : null;
        return bean;
         }

}

///直播间分类对象
export class RoomCategory {
    id 
    name 
    image 
    /**
     * @param json
     * @returns RoomCategory
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RoomCategory();
        bean.id = json['id'];
        bean.name = json['name'];
        bean.image = json['image'];
        return bean;
         }

}

///直播间贡献数据Item
export class RoomContribute {
    total  //贡献值 
    user  //对应用户 
    /**
     * @param json
     * @returns RoomContribute
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RoomContribute();
        bean.total = json['total'];
        bean.user = json['user'] != null ? UserInfo.fromJson(json['user']) : null;
        return bean;
         }

}

///直播间详情对象
export class RoomDetail {
    room_no 
    category 
    anchor 
    name 
    thumb 
    is_living 
    level_info 
    introduction 
    province 
    city 
    stream_name 
    exp 
    guard_num  //守护人数 
    manager_num  //房管人数 
    audience_num  //当前直播观众人数 
    popularity  //人气值 
    started_at  //本次直播开始时间 
    stream_play_url  //视频地址 
    is_pk  //当前是否在PK 
    now_contribution  //本场总贡献值(直播间左上方数字) 
    audience  //当前用户在当前直播间的数据信息 
    level  //直播间等级 
    next  //下滑直播间 
    prev  //上滑直播间 
    is_headline  //是否为头条主播 
    is_week_star  //是否为周星主播 
    recommend  //推荐级别0正常/1推荐/2超级推荐 
    url  //PC端网址 
    share_params 
    /**
     * @param json
     * @returns RoomDetail
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RoomDetail();
        bean.room_no = json['room_no'];
        bean.category = json['category'] != null ? RoomCategory.fromJson(json['category']) : null;
        bean.anchor = json['anchor'] != null ? AnchorInfo.fromJson(json['anchor']) : null;
        bean.name = json['name'];
        bean.thumb = json['thumb'];
        bean.is_living = json['is_living'];
        bean.level_info = json['level_info'] != null ? RoomLevel.fromJson(json['level_info']) : null;
        bean.introduction = json['introduction'];
        bean.province = json['province'];
        bean.city = json['city'];
        bean.stream_name = json['stream_name'];
        bean.exp = json['exp'];
        bean.guard_num = json['guard_num'];
        bean.manager_num = json['manager_num'];
        bean.audience_num = json['audience_num'];
        bean.popularity = json['popularity'];
        bean.started_at = json['started_at'];
        bean.stream_play_url = json['stream_play_url'];
        bean.is_pk = json['is_pk'];
        bean.now_contribution = json['now_contribution'];
        bean.audience = json['audience'] != null ? Audience.fromJson(json['audience']) : null;
        bean.level = json['level'];
        bean.next = json['next'] != null ? RoomItem.fromJson(json['next']) : null;
        bean.prev = json['prev'] != null ? RoomItem.fromJson(json['prev']) : null;
        bean.is_headline = json['is_headline'];
        bean.is_week_star = json['is_week_star'];
        bean.recommend = json['recommend'];
        bean.url = json['url'];
        bean.share_params = json['share_params'] != null ? RoomShareParam.fromJson(json['share_params']) : null;
        return bean;
         }

}

///直播间礼物列表(主要用于在直播间获取礼物时候使用)
export class RoomGift {
    name 
    type 
    is_hot 
    price 
    image 
    play_type 
    animation 
    is_global 
    is_multiple 
    remark 
    can_present 
    badge_text 
    id 
    amount  //如果是从背包获取的礼物,则有改数字表示拥有的数量,否则为null 
    badge_color  //角标颜色值 
    /**
     * @param json
     * @returns RoomGift
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RoomGift();
        bean.name = json['name'];
        bean.type = json['type'];
        bean.is_hot = json['is_hot'];
        bean.price = json['price'];
        bean.image = json['image'];
        bean.play_type = json['play_type'];
        bean.animation = json['animation'];
        bean.is_global = json['is_global'];
        bean.is_multiple = json['is_multiple'];
        bean.remark = json['remark'];
        bean.can_present = json['can_present'];
        bean.badge_text = json['badge_text'];
        bean.id = json['id'];
        bean.amount = json['amount'];
        bean.badge_color = json['badge_color'];
        return bean;
         }

}

///直播间获取礼物的分组
export class RoomGiftGroup {
    category 
    items 
    /**
     * @param json
     * @returns RoomGiftGroup
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RoomGiftGroup();
        bean.category = json['category'] != null ? GiftCategory.fromJson(json['category']) : null;
        if (json['items'] != null) {
                        bean.items = json['items'].map(item => RoomGift.fromJson(item));
                    }
        return bean;
         }

}

///直播间列表item对象,一般用户直播间列表,或者查询搜索等
export class RoomItem {
    room_no 
    category 
    anchor 
    name 
    thumb 
    is_living 
    introduction 
    province 
    city 
    stream_name 
    exp 
    audience_num 
    popularity 
    started_at 
    level_info 
    is_pk 
    distance  //距离你的位置(nearby接口返回,其他接口为null) 
    level  //直播间等级 
    badge_text  //左上角角标文字 
    is_headline  //是否为头条主播 
    is_week_star  //是否为周星主播 
    recommend  //推荐级别0正常/1推荐/2超级推荐 
    stream_play_url  //播放地址 
    url  //PC端网址 
    /**
     * @param json
     * @returns RoomItem
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RoomItem();
        bean.room_no = json['room_no'];
        bean.category = json['category'] != null ? RoomCategory.fromJson(json['category']) : null;
        bean.anchor = json['anchor'] != null ? AnchorInfo.fromJson(json['anchor']) : null;
        bean.name = json['name'];
        bean.thumb = json['thumb'];
        bean.is_living = json['is_living'];
        bean.introduction = json['introduction'];
        bean.province = json['province'];
        bean.city = json['city'];
        bean.stream_name = json['stream_name'];
        bean.exp = json['exp'];
        bean.audience_num = json['audience_num'];
        bean.popularity = json['popularity'];
        bean.started_at = json['started_at'];
        bean.level_info = json['level_info'] != null ? RoomLevel.fromJson(json['level_info']) : null;
        bean.is_pk = json['is_pk'];
        bean.distance = json['distance'];
        bean.level = json['level'];
        bean.badge_text = json['badge_text'];
        bean.is_headline = json['is_headline'];
        bean.is_week_star = json['is_week_star'];
        bean.recommend = json['recommend'];
        bean.stream_play_url = json['stream_play_url'];
        bean.url = json['url'];
        return bean;
         }

}

///直播间等级,一般附属在直播间对象上
export class RoomLevel {
    level 
    name 
    image 
    badge 
    max_exp 
    next_level  //下一等级信息 
    /**
     * @param json
     * @returns RoomLevel
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RoomLevel();
        bean.level = json['level'];
        bean.name = json['name'];
        bean.image = json['image'];
        bean.badge = json['badge'];
        bean.max_exp = json['max_exp'];
        bean.next_level = json['next_level'] != null ? RoomLevel.fromJson(json['next_level']) : null;
        return bean;
         }

}

///PK的用户列表对象,主要用户PK列表
export class RoomPK {
    started_at 
    type 
    introduction 
    from_contribution 
    to_contribution 
    from_room 
    to_room 
    /**
     * @param json
     * @returns RoomPK
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RoomPK();
        bean.started_at = json['started_at'];
        bean.type = json['type'];
        bean.introduction = json['introduction'];
        bean.from_contribution = json['from_contribution'];
        bean.to_contribution = json['to_contribution'];
        bean.from_room = json['from_room'] != null ? RoomItem.fromJson(json['from_room']) : null;
        bean.to_room = json['to_room'] != null ? RoomItem.fromJson(json['to_room']) : null;
        return bean;
         }

}

///直播间分享参数对象
export class RoomShareParam {
    title 
    text 
    url 
    image 
    /**
     * @param json
     * @returns RoomShareParam
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RoomShareParam();
        bean.title = json['title'];
        bean.text = json['text'];
        bean.url = json['url'];
        bean.image = json['image'];
        return bean;
         }

}

///直播封面Item,用于直播间封面管理
export class RoomThumb {
    id 
    user_id 
    room_id 
    url 
    status 
    reject_reason 
    sort 
    created_at 
    updated_at 
    /**
     * @param json
     * @returns RoomThumb
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new RoomThumb();
        bean.id = json['id'];
        bean.user_id = json['user_id'];
        bean.room_id = json['room_id'];
        bean.url = json['url'];
        bean.status = json['status'];
        bean.reject_reason = json['reject_reason'];
        bean.sort = json['sort'];
        bean.created_at = json['created_at'];
        bean.updated_at = json['updated_at'];
        return bean;
         }

}

///后台系统设置
export class Setting {
    gold_name  //金币名称 
    gold_rate  //金币和RMB比例(充值时换算用) 
    vip_badge  //VIP角标图片 
    default_avatar  //默认头像 
    default_thumb  //默认封面 
    service_contact  //客服联系方式 
    site_copyright  //版权 
    earnings_name  //主播收益名称 
    club_price  //粉丝团入团费用 
    red_envelop_min_amount  //最小发红包金额 
    red_envelop_max_amount  //最大发红包金额 
    red_envelop_min_total  //最小发红包数量 
    red_envelop_max_total  //最大发红包数量 
    site_url  //PC网址 
    site_m_url  //H5网址 
    download_url  //下载网址 
    chat_barrage_price  //10 
    payment_in_app  //true 
    /**
     * @param json
     * @returns Setting
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Setting();
        bean.gold_name = json['gold_name'];
        bean.gold_rate = json['gold_rate'];
        bean.vip_badge = json['vip_badge'];
        bean.default_avatar = json['default_avatar'];
        bean.default_thumb = json['default_thumb'];
        bean.service_contact = json['service_contact'];
        bean.site_copyright = json['site_copyright'];
        bean.earnings_name = json['earnings_name'];
        bean.club_price = json['club_price'];
        bean.red_envelop_min_amount = json['red_envelop_min_amount'];
        bean.red_envelop_max_amount = json['red_envelop_max_amount'];
        bean.red_envelop_min_total = json['red_envelop_min_total'];
        bean.red_envelop_max_total = json['red_envelop_max_total'];
        bean.site_url = json['site_url'];
        bean.site_m_url = json['site_m_url'];
        bean.download_url = json['download_url'];
        bean.chat_barrage_price = json['chat_barrage_price'];
        bean.payment_in_app = json['payment_in_app'];
        return bean;
         }

}

///签到聚合数据
export class Signin {
    today_signin  //今日是否已签到 
    continuous  //当前已连续签到天数 
    rules 
    rule_description  //签到说明 
    list 
    today_rule  //今日签到的奖励 
    /**
     * @param json
     * @returns Signin
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Signin();
        bean.today_signin = json['today_signin'];
        bean.continuous = json['continuous'];
        if (json['rules'] != null) {
                        bean.rules = json['rules'].map(item => SigninRule.fromJson(item));
                    }
        bean.rule_description = json['rule_description'];
        if (json['list'] != null) {
                        bean.list = json['list'].map(item => SigninItem.fromJson(item));
                    }
        bean.today_rule = json['today_rule'] != null ? SigninItem.fromJson(json['today_rule']) : null;
        return bean;
         }

}

///签到规则的奖励,,用于每一次签到将要奖励的详细内容
export class SigninAward {
    id  //只有当类型为礼物的时候才有ID 
    type  //0:经验值 1:金币 2:礼物 3:抽奖机会(待定) 
    image 
    amount 
    remark 
    /**
     * @param json
     * @returns SigninAward
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SigninAward();
        bean.id = json['id'];
        bean.type = json['type'];
        bean.image = json['image'];
        bean.amount = json['amount'];
        bean.remark = json['remark'];
        return bean;
         }

}

///用户的每一天签到情况和奖励信息,一般用户签到grid类型布局的循环
export class SigninItem {
    days 
    image 
    title 
    award 
    is_signin  //当期日期是否已签到 
    /**
     * @param json
     * @returns SigninItem
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SigninItem();
        bean.days = json['days'];
        bean.image = json['image'];
        bean.title = json['title'];
        if (json['award'] != null) {
                        bean.award = json['award'].map(item => SigninAward.fromJson(item));
                    }
        bean.is_signin = json['is_signin'];
        return bean;
         }

}

///用户的签到记录
export class SigninRecord {
    id 
    user_id 
    days  //连续不间断的天数 
    rewarded  //获得的奖励 
    created_at 
    /**
     * @param json
     * @returns SigninRecord
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SigninRecord();
        bean.id = json['id'];
        bean.user_id = json['user_id'];
        bean.days = json['days'];
        if (json['rewarded'] != null) {
                        bean.rewarded = json['rewarded'].map(item => SigninReward.fromJson(item));
                    }
        bean.created_at = json['created_at'];
        return bean;
         }

}

///我签到获得的奖励
export class SigninReward {
    gift 
    type 
    amount 
    message 
    /**
     * @param json
     * @returns SigninReward
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SigninReward();
        bean.gift = json['gift']
        bean.type = json['type'];
        bean.amount = json['amount'];
        bean.message = json['message'];
        return bean;
         }

}

///存在奖励的签到的规则,下方list类型的展示
export class SigninRule {
    days 
    title 
    image 
    /**
     * @param json
     * @returns SigninRule
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SigninRule();
        bean.days = json['days'];
        bean.title = json['title'];
        bean.image = json['image'];
        return bean;
         }

}

///简单礼物模型,不包含是否能赠送等逻辑,只用于账单列表
export class SimpleGift {
    id 
    name 
    type 
    price 
    image 
    remark 
    /**
     * @param json
     * @returns SimpleGift
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SimpleGift();
        bean.id = json['id'];
        bean.name = json['name'];
        bean.type = json['type'];
        bean.price = json['price'];
        bean.image = json['image'];
        bean.remark = json['remark'];
        return bean;
         }

}

///幻灯片对象
export class Slide {
    code 
    name 
    aspect_ratio  //宽度➗高度 
    details  //图片列表 
    /**
     * @param json
     * @returns Slide
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Slide();
        bean.code = json['code'];
        bean.name = json['name'];
        bean.aspect_ratio = json['aspect_ratio'];
        if (json['details'] != null) {
                        bean.details = json['details'].map(item => SlideItem.fromJson(item));
                    }
        return bean;
         }

}

///幻灯片的每个图片
export class SlideItem {
    link 
    image 
    /**
     * @param json
     * @returns SlideItem
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SlideItem();
        bean.link = json['link'];
        bean.image = json['image'];
        return bean;
         }

}

///发送短信成功之后返回的数据模型
export class SmsResult {
    interval 
    code 
    mobile 
    /**
     * @param json
     * @returns SmsResult
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SmsResult();
        bean.interval = json['interval'];
        bean.code = json['code'];
        bean.mobile = json['mobile'];
        return bean;
         }

}

///receiveAnnouncement 消息数据
export class SocketAnnouncement {
    content 
    color 
    /**
     * @param json
     * @returns SocketAnnouncement
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketAnnouncement();
        bean.content = json['content'];
        bean.color = json['color'];
        return bean;
         }

}

///receiveGift 事件对象
export class SocketGiftMessage {
    name 
    image 
    num 
    user 
    effect  //特效 
    /**
     * @param json
     * @returns SocketGiftMessage
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketGiftMessage();
        bean.name = json['name'];
        bean.image = json['image'];
        bean.num = json['num'];
        bean.user = json['user'] != null ? UserInfo.fromJson(json['user']) : null;
        bean.effect = json['effect'] != null ? Effect.fromJson(json['effect']) : null;
        return bean;
         }

}

///receiveGlobalGift09 事件对象 全局礼物跑道消息
export class SocketGlobalGiftMessage {
    name 
    image 
    num 
    svga_animation 
    user 
    anchor 
    room_no 
    /**
     * @param json
     * @returns SocketGlobalGiftMessage
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketGlobalGiftMessage();
        bean.name = json['name'];
        bean.image = json['image'];
        bean.num = json['num'];
        bean.svga_animation = json['svga_animation'];
        bean.user = json['user'] != null ? UserInfo.fromJson(json['user']) : null;
        bean.anchor = json['anchor'] != null ? AnchorInfo.fromJson(json['anchor']) : null;
        bean.room_no = json['room_no'];
        return bean;
         }

}

///locked 事件对象,当收到该事件,需要退出直播间,关闭直播,断开socket
export class SocketLocked {
    content  //给用户的提示 
    user 
    locked_at 
    locked_expire  //被封禁到期时间,如果为null表示永久 
    /**
     * @param json
     * @returns SocketLocked
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketLocked();
        bean.content = json['content'];
        bean.user = json['user'] != null ? UserInfo.fromJson(json['user']) : null;
        bean.locked_at = json['locked_at'];
        bean.locked_expire = json['locked_expire'];
        return bean;
         }

}

///receiveMessage 事件数据对象
export class SocketMessage {
    key 
    interval 
    next 
    content 
    color 
    user 
    is_barrage  //是否是漂浮弹幕 
    /**
     * @param json
     * @returns SocketMessage
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketMessage();
        bean.key = json['key'];
        bean.interval = json['interval'];
        bean.next = json['next'];
        bean.content = json['content'];
        bean.color = json['color'];
        bean.user = json['user'] != null ? UserInfo.fromJson(json['user']) : null;
        bean.is_barrage = json['is_barrage'];
        return bean;
         }

}

///pkAgree 事件对象
export class SocketPKAgree {
    id 
    type 
    introduction 
    target_user 
    target_accelerate_url  //对方的高速流播放地址 
    /**
     * @param json
     * @returns SocketPKAgree
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketPKAgree();
        bean.id = json['id'];
        bean.type = json['type'];
        bean.introduction = json['introduction'];
        bean.target_user = json['target_user'] != null ? AnchorInfo.fromJson(json['target_user']) : null;
        bean.target_accelerate_url = json['target_accelerate_url'];
        return bean;
         }

}

///pkStart / pkEnd / pkClose / pkUpdateProgress 公用的对象
export class SocketPKDetailMessage {
    id 
    type 
    introduction 
    requested_at  //发起时间 
    started_at  //开始时间 
    ended_at  //结束时间(可以用来判断在PK还是在惩罚) 
    closed_at  //关闭时间 
    pk_duration  //PK时长 
    punish_duration  //惩罚时长 
    punish_time_left  //惩罚剩余时间 
    pk_time_left  //PK剩余时间 
    self_user 
    target_user 
    self_contribute_total  //PK过程中,贡献总数 
    target_contribute_total  //PK过程中,对方贡献总数 
    self_contribute_list  //PK过程中,贡献榜单前3 
    target_contribute_list  //PK过程中,对方贡献榜单前3 
    result  //1赢 -1 输 0 平局 
    stream_play_url  //混流播放地址,需要在start的时候该地址,在close的时候关闭改地址 
    /**
     * @param json
     * @returns SocketPKDetailMessage
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketPKDetailMessage();
        bean.id = json['id'];
        bean.type = json['type'];
        bean.introduction = json['introduction'];
        bean.requested_at = json['requested_at'];
        bean.started_at = json['started_at'];
        bean.ended_at = json['ended_at'];
        bean.closed_at = json['closed_at'];
        bean.pk_duration = json['pk_duration'];
        bean.punish_duration = json['punish_duration'];
        bean.punish_time_left = json['punish_time_left'];
        bean.pk_time_left = json['pk_time_left'];
        bean.self_user = json['self_user'] != null ? AnchorInfo.fromJson(json['self_user']) : null;
        bean.target_user = json['target_user'] != null ? AnchorInfo.fromJson(json['target_user']) : null;
        bean.self_contribute_total = json['self_contribute_total'];
        bean.target_contribute_total = json['target_contribute_total'];
        if (json['self_contribute_list'] != null) {
                        bean.self_contribute_list = json['self_contribute_list'].map(item => RoomContribute.fromJson(item));
                    }
        if (json['target_contribute_list'] != null) {
                        bean.target_contribute_list = json['target_contribute_list'].map(item => RoomContribute.fromJson(item));
                    }
        bean.result = json['result'];
        bean.stream_play_url = json['stream_play_url'];
        return bean;
         }

}

///pkReject 事件对象 PK被拒绝
export class SocketPKReject {
    id 
    requested_at 
    type 
    introduction 
    target_user  //对方用户 
    status 
    /**
     * @param json
     * @returns SocketPKReject
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketPKReject();
        bean.id = json['id'];
        bean.requested_at = json['requested_at'];
        bean.type = json['type'];
        bean.introduction = json['introduction'];
        bean.target_user = json['target_user'] != null ? AnchorInfo.fromJson(json['target_user']) : null;
        bean.status = json['status'];
        return bean;
         }

}

///pkRequest 事件对象 PK请求
export class SocketPKRequest {
    id 
    requested_at 
    type 
    introduction 
    target_user 
    status 
    timeout 
    /**
     * @param json
     * @returns SocketPKRequest
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketPKRequest();
        bean.id = json['id'];
        bean.requested_at = json['requested_at'];
        bean.type = json['type'];
        bean.introduction = json['introduction'];
        bean.target_user = json['target_user'] != null ? AnchorInfo.fromJson(json['target_user']) : null;
        bean.status = json['status'];
        bean.timeout = json['timeout'];
        return bean;
         }

}

///pkRequestTimeout09 事件对象,超时未同意或拒绝,如果有弹框需要关闭
export class SocketPKTimeout {
    id 
    requested_at 
    type 
    introduction 
    target_user 
    status 
    /**
     * @param json
     * @returns SocketPKTimeout
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketPKTimeout();
        bean.id = json['id'];
        bean.requested_at = json['requested_at'];
        bean.type = json['type'];
        bean.introduction = json['introduction'];
        bean.target_user = json['target_user'] != null ? AnchorInfo.fromJson(json['target_user']) : null;
        bean.status = json['status'];
        return bean;
         }

}

///收到中奖提示信息
export class SocketReceiveGlobalLottery {
    amount  //数量 
    game  //游戏名称 
    image  //奖品图片(之后是礼物才有,如果是金币,需要使用本地图片) 
    name  //奖品名称(礼物名称/金币名称) 
    type  //中奖类型 0 金币 1 礼物 2 未中奖(可以忽略,不会收到通知) 
    user 
    /**
     * @param json
     * @returns SocketReceiveGlobalLottery
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketReceiveGlobalLottery();
        bean.amount = json['amount'];
        bean.game = json['game'];
        bean.image = json['image'];
        bean.name = json['name'];
        bean.type = json['type'];
        bean.user = json['user'] != null ? UserInfo.fromJson(json['user']) : null;
        return bean;
         }

}

///收到中奖提示信息
export class SocketReceiveLottery {
    amount  //数量 
    game  //游戏名称 
    image  //奖品图片(之后是礼物才有,如果是金币,需要使用本地图片) 
    name  //奖品名称(礼物名称/金币名称) 
    type  //中奖类型 0 金币 1 礼物 2 未中奖(可以忽略,不会收到通知) 
    user 
    /**
     * @param json
     * @returns SocketReceiveLottery
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketReceiveLottery();
        bean.amount = json['amount'];
        bean.game = json['game'];
        bean.image = json['image'];
        bean.name = json['name'];
        bean.type = json['type'];
        bean.user = json['user'] != null ? UserInfo.fromJson(json['user']) : null;
        return bean;
         }

}

///收到中奖提示信息
export class SocketReceiveLotteryMessage {
    amount  //数量 
    game  //游戏名称 
    image  //奖品图片(之后是礼物才有,如果是金币,需要使用本地图片) 
    name  //奖品名称(礼物名称/金币名称) 
    type  //中奖类型 0 金币 1 礼物 2 未中奖(可以忽略,不会收到通知) 
    user 
    /**
     * @param json
     * @returns SocketReceiveLotteryMessage
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketReceiveLotteryMessage();
        bean.amount = json['amount'];
        bean.game = json['game'];
        bean.image = json['image'];
        bean.name = json['name'];
        bean.type = json['type'];
        bean.user = json['user'] != null ? UserInfo.fromJson(json['user']) : null;
        return bean;
         }

}

///红包中奖信息
export class SocketRedEnvelopeLucky {
    user  //中奖用户 
    amount  //中奖金额 
    /**
     * @param json
     * @returns SocketRedEnvelopeLucky
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketRedEnvelopeLucky();
        bean.user = json['user'] != null ? UserInfo.fromJson(json['user']) : null;
        bean.amount = json['amount'];
        return bean;
         }

}

///用户告知用户有红包可以抢,如果用户已经在直播间,收到发红包消息之后,会直接收到此消息,如果在发红包之后进入直播间,会在进入房间的时候收到次消息
export class SocketRedEnvelopeNotify {
    time_remaining  //开抢倒计时(&lt;&#x3D;0为可以抢状态),需要前台自己做倒计时 
    red_envelope  //红包 
    target_time  //抢红包开始时间 格式yyyy-MM-dd HH:mm:ss 
    rob_status  //抢购状态 0 未开始(配合倒计时) 1 可以抢 2 已抢 
    /**
     * @param json
     * @returns SocketRedEnvelopeNotify
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketRedEnvelopeNotify();
        bean.time_remaining = json['time_remaining'];
        bean.red_envelope = json['red_envelope'] != null ? RedEnvelope.fromJson(json['red_envelope']) : null;
        bean.target_time = json['target_time'];
        bean.rob_status = json['rob_status'];
        return bean;
         }

}

///receiveSystemMessage 事件对象
export class SocketSystemMessage {
    user 
    type  //消息类型 如有需要用到,需要具体对接 
    data 
    color 
    datetime 
    /**
     * @param json
     * @returns SocketSystemMessage
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketSystemMessage();
        bean.user = json['user'] != null ? UserInfo.fromJson(json['user']) : null;
        bean.type = json['type'];
        bean.data = json['data'];
        bean.color = json['color'];
        bean.datetime = json['datetime'];
        return bean;
         }

}

///welcome 事件对象
export class SocketWelcome {
    content 
    user 
    effect  //特效 
    /**
     * @param json
     * @returns SocketWelcome
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new SocketWelcome();
        bean.content = json['content'];
        bean.user = json['user'] != null ? UserInfo.fromJson(json['user']) : null;
        bean.effect = json['effect'] != null ? Effect.fromJson(json['effect']) : null;
        return bean;
         }

}

///我的直播间流状态
export class StreamStatus {
    stream_name 
    is_active 
    /**
     * @param json
     * @returns StreamStatus
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new StreamStatus();
        bean.stream_name = json['stream_name'];
        bean.is_active = json['is_active'];
        return bean;
         }

}

///用户任务Item模型
export class Task {
    id 
    name  //任务名 
    image  //图片 
    introduction  //任务描述 
    award_text  //奖励文字 
    target  //目标进度 
    progress  //完成进度  
    complete_status  //完成状态 0 未完成 1 待领取奖励 2 已完成 
    receive_award_id  //用来领取奖励的id 
    /**
     * @param json
     * @returns Task
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Task();
        bean.id = json['id'];
        bean.name = json['name'];
        bean.image = json['image'];
        bean.introduction = json['introduction'];
        bean.award_text = json['award_text'];
        bean.target = json['target'];
        bean.progress = json['progress'];
        bean.complete_status = json['complete_status'];
        bean.receive_award_id = json['receive_award_id'];
        return bean;
         }

}

///签到规则的奖励,,用于每一次签到将要奖励的详细内容
export class TaskAward {
    id  //只有当类型为礼物的时候才有ID 
    type  //0:经验值 1:金币 2:礼物 3:抽奖机会(待定) 
    image 
    amount 
    remark 
    /**
     * @param json
     * @returns TaskAward
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new TaskAward();
        bean.id = json['id'];
        bean.type = json['type'];
        bean.image = json['image'];
        bean.amount = json['amount'];
        bean.remark = json['remark'];
        return bean;
         }

}

///任务分组组合模型
export class TaskGroup {
    category  //分组名 newbie 新手任务 day每日任务 advanced精品任务 
    list  //任务列表 
    /**
     * @param json
     * @returns TaskGroup
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new TaskGroup();
        bean.category = json['category'];
        if (json['list'] != null) {
                        bean.list = json['list'].map(item => Task.fromJson(item));
                    }
        return bean;
         }

}

///用户登陆/注册返回的token信息
export class Token {
    access_token 
    token_type 
    expires_in 
    im_sig 
    user_id  //用户ID 
    /**
     * @param json
     * @returns Token
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Token();
        bean.access_token = json['access_token'];
        bean.token_type = json['token_type'];
        bean.expires_in = json['expires_in'];
        bean.im_sig = json['im_sig'];
        bean.user_id = json['user_id'];
        return bean;
         }

}

///上传图片之后返回的数据对象
export class UploadResult {
    url 
    /**
     * @param json
     * @returns UploadResult
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new UploadResult();
        bean.url = json['url'];
        return bean;
         }

}

///用户相册
export class UserAlbum {
    url 
    /**
     * @param json
     * @returns UserAlbum
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new UserAlbum();
        bean.url = json['url'];
        return bean;
         }

}

///我的银行账户item对象
export class UserBank {
    id 
    account 
    name 
    bank 
    /**
     * @param json
     * @returns UserBank
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new UserBank();
        bean.id = json['id'];
        bean.account = json['account'];
        bean.name = json['name'];
        bean.bank = json['bank'] != null ? Bank.fromJson(json['bank']) : null;
        return bean;
         }

}

///用户的家族信息,一般附加在用户模型上
export class UserFamily {
    id 
    name 
    avatar 
    badge 
    rank 
    introduction 
    contribution 
    /**
     * @param json
     * @returns UserFamily
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new UserFamily();
        bean.id = json['id'];
        bean.name = json['name'];
        bean.avatar = json['avatar'];
        bean.badge = json['badge'];
        bean.rank = json['rank'];
        bean.introduction = json['introduction'];
        bean.contribution = json['contribution'];
        return bean;
         }

}

///用户信息,主要用于关联其他模型的时候,或者查看一个用户的信息返回的对象
export class UserInfo {
    id 
    nickname 
    avatar_small 
    signature 
    fans_num  //关注我的人数 
    follow_num  //我关注的人数 
    gender  //性别 
    level_info  //等级信息 
    family  //家族信息 
    zodiac  //星座 
    badges  //徽章列表 
    exp  //当前经验 
    room  //用户的直播间 
    audience  //如果为直播间相关接口,表示为该用户在直播间的观众信息 
    is_followed  //是否已关注该用户 
    avatar_big  //大头像 
    is_anchor  //是否是直播 
    birthday  //生日 
    province  //省份 
    city  //城市 
    is_blacked  //是否已拉黑用户 
    /**
     * @param json
     * @returns UserInfo
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new UserInfo();
        bean.id = json['id'];
        bean.nickname = json['nickname'];
        bean.avatar_small = json['avatar_small'];
        bean.signature = json['signature'];
        bean.fans_num = json['fans_num'];
        bean.follow_num = json['follow_num'];
        bean.gender = json['gender'];
        bean.level_info = json['level_info'] != null ? UserLevel.fromJson(json['level_info']) : null;
        bean.family = json['family'] != null ? UserFamily.fromJson(json['family']) : null;
        bean.zodiac = json['zodiac'];
        if (json['badges'] != null) {
                        bean.badges = json['badges'].map(item => Badge.fromJson(item));
                    }
        bean.exp = json['exp'];
        bean.room = json['room'] != null ? UserRoom.fromJson(json['room']) : null;
        bean.audience = json['audience'] != null ? Audience.fromJson(json['audience']) : null;
        bean.is_followed = json['is_followed'];
        bean.avatar_big = json['avatar_big'];
        bean.is_anchor = json['is_anchor'];
        bean.birthday = json['birthday'];
        bean.province = json['province'];
        bean.city = json['city'];
        bean.is_blacked = json['is_blacked'];
        return bean;
         }

}

///用户等级信息,一般附加在用户模型上
export class UserLevel {
    level 
    name 
    image 
    badge 
    color 
    max_exp 
    next_level  //下一等级信息 
    /**
     * @param json
     * @returns UserLevel
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new UserLevel();
        bean.level = json['level'];
        bean.name = json['name'];
        bean.image = json['image'];
        bean.badge = json['badge'];
        bean.color = json['color'];
        bean.max_exp = json['max_exp'];
        bean.next_level = json['next_level'] != null ? UserLevel.fromJson(json['next_level']) : null;
        return bean;
         }

}

///
export class UserMedal {
    id 
    name 
    image 
    remark 
    obtained_at  //获取时间 
    expired_at  //到期时间 
    is_mounted  //是否已经装备 
    /**
     * @param json
     * @returns UserMedal
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new UserMedal();
        bean.id = json['id'];
        bean.name = json['name'];
        bean.image = json['image'];
        bean.remark = json['remark'];
        bean.obtained_at = json['obtained_at'];
        bean.expired_at = json['expired_at'];
        bean.is_mounted = json['is_mounted'];
        return bean;
         }

}

///用户的靓号,用于个人中心-&gt;我的靓号列表
export class UserNiceNumber {
    id 
    number 
    badge 
    is_mounted 
    /**
     * @param json
     * @returns UserNiceNumber
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new UserNiceNumber();
        bean.id = json['id'];
        bean.number = json['number'];
        bean.badge = json['badge'];
        bean.is_mounted = json['is_mounted'];
        return bean;
         }

}

///用户个人信息,只用于个人中心的用户查询
export class UserProfile {
    id 
    nickname 
    mobile 
    avatar_small 
    avatar_big 
    birthday 
    gender 
    signature 
    is_anchor  //是否为主播 
    follow_num 
    fans_num 
    is_vip 
    vip_expiration  //VIP到期时间 
    vip_level  //VIP等级 
    level  //用户等级 
    level_info 
    exp 
    level_rank  //等级排行 
    province 
    city 
    address 
    created_at 
    today_signin  //今日是否已签到 
    family  //家族信息 
    zodiac 
    badges 
    room 
    gold  //账户余额 
    unread_message  //未读消息数 
    settings  //用户的个人设置 
    gold_earnings  //收益 
    oauth_binds 
    /**
     * @param json
     * @returns UserProfile
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new UserProfile();
        bean.id = json['id'];
        bean.nickname = json['nickname'];
        bean.mobile = json['mobile'];
        bean.avatar_small = json['avatar_small'];
        bean.avatar_big = json['avatar_big'];
        bean.birthday = json['birthday'];
        bean.gender = json['gender'];
        bean.signature = json['signature'];
        bean.is_anchor = json['is_anchor'];
        bean.follow_num = json['follow_num'];
        bean.fans_num = json['fans_num'];
        bean.is_vip = json['is_vip'];
        bean.vip_expiration = json['vip_expiration'];
        bean.vip_level = json['vip_level'];
        bean.level = json['level'];
        bean.level_info = json['level_info'] != null ? UserLevel.fromJson(json['level_info']) : null;
        bean.exp = json['exp'];
        bean.level_rank = json['level_rank'];
        bean.province = json['province'];
        bean.city = json['city'];
        bean.address = json['address'];
        bean.created_at = json['created_at'];
        bean.today_signin = json['today_signin'];
        bean.family = json['family'] != null ? UserFamily.fromJson(json['family']) : null;
        bean.zodiac = json['zodiac'];
        if (json['badges'] != null) {
                        bean.badges = json['badges'].map(item => Badge.fromJson(item));
                    }
        bean.room = json['room'] != null ? UserRoom.fromJson(json['room']) : null;
        bean.gold = json['gold'];
        bean.unread_message = json['unread_message'];
        bean.settings = json['settings'] != null ? UserSetting.fromJson(json['settings']) : null;
        bean.gold_earnings = json['gold_earnings'];
        bean.oauth_binds = json['oauth_binds'] != null ? OAuthBind.fromJson(json['oauth_binds']) : null;
        return bean;
         }

}

///用户排行的item对象,用户排行榜
export class UserRank {
    user_id 
    total_amount 
    user 
    /**
     * @param json
     * @returns UserRank
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new UserRank();
        bean.user_id = json['user_id'];
        bean.total_amount = json['total_amount'];
        bean.user = json['user'] != null ? UserInfo.fromJson(json['user']) : null;
        return bean;
         }

}

///用户的直播间,.主要用户返回用户信息时候的关联信息,一般作为附属信息
export class UserRoom {
    room_no 
    name 
    thumb 
    is_living 
    level_info 
    exp 
    is_pk 
    level  //直播间等级 
    /**
     * @param json
     * @returns UserRoom
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new UserRoom();
        bean.room_no = json['room_no'];
        bean.name = json['name'];
        bean.thumb = json['thumb'];
        bean.is_living = json['is_living'];
        bean.level_info = json['level_info'] != null ? RoomLevel.fromJson(json['level_info']) : null;
        bean.exp = json['exp'];
        bean.is_pk = json['is_pk'];
        bean.level = json['level'];
        return bean;
         }

}

///用户的相关设置
export class UserSetting {
    id 
    show_gift_animation  //显示礼物动画(主要用于前端) 
    show_vehicle_animation  //显示车辆动画(主要用于前端) 
    message_notify  //收到消息通知(后台用) 
    live_notify  //开播消息通知(后台用) 
    /**
     * @param json
     * @returns UserSetting
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new UserSetting();
        bean.id = json['id'];
        bean.show_gift_animation = json['show_gift_animation'];
        bean.show_vehicle_animation = json['show_vehicle_animation'];
        bean.message_notify = json['message_notify'];
        bean.live_notify = json['live_notify'];
        return bean;
         }

}

///坐骑对象,用于商城
export class Vehicle {
    id 
    name 
    image 
    animation 
    price 
    line_price 
    detail 
    type  //类型0一般1VIP限定2等级限定3活动限定 
    need_level  //需要等级 
    can_buy  //是否能购买 
    placeholder  //占位符文字(如果不能购买) 
    is_mounted  //是否已装备 
    expired_at  //到期时间 
    /**
     * @param json
     * @returns Vehicle
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new Vehicle();
        bean.id = json['id'];
        bean.name = json['name'];
        bean.image = json['image'];
        bean.animation = json['animation'];
        bean.price = json['price'];
        bean.line_price = json['line_price'];
        bean.detail = json['detail'];
        bean.type = json['type'];
        bean.need_level = json['need_level'];
        bean.can_buy = json['can_buy'];
        bean.placeholder = json['placeholder'];
        bean.is_mounted = json['is_mounted'];
        bean.expired_at = json['expired_at'];
        return bean;
         }

}

///VIP价格列表
export class VipPrice {
    id 
    name  //VIP标题 
    long  //时长标识(购买时候需要传入此标识) 
    price  //价格 
    rmb_price  //转化为人民币的价格 
    /**
     * @param json
     * @returns VipPrice
     */
    static fromJson(json) {
        if (json == null) return null;
        const bean = new VipPrice();
        bean.id = json['id'];
        bean.name = json['name'];
        bean.long = json['long'];
        bean.price = json['price'];
        bean.rmb_price = json['rmb_price'];
        return bean;
         }

}

import Vue from 'vue'
import './plugins/axios'
import App from './App.vue'
import store from './store'
import router from './router'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import VueClipboard from 'vue-clipboard2'
import '@/style/style.scss'

Vue.use(ElementUI, {})
Vue.use(VueClipboard)
Vue.config.productionTip = false
Vue.prototype.$open = function (url) {
    window.open(url, '_blank');
}

if(process.env.NODE_ENV === 'production'){
    window.console = {
        log: () => {
        }
    }
}


new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')

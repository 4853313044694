import Vue from 'vue'
import {fetchMyRoomInfo, fetchSettings} from "@/reqeust/api";
import {MyRoom} from "@/model";

const state = {
    myRoom: MyRoom.fromJson(null)
}

const mutations = {
    SET_MY_ROOM: (state, data) => {
        Vue.set(state, 'myRoom', data)
    },
}

const actions = {
    getMyRoom({commit}) {
        return new Promise((resolve, reject) => {
            fetchMyRoomInfo().then(data => {
                commit('SET_MY_ROOM', data)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}


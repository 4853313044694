export class Apis {
    static auth_login = '/auth/login';
    static auth_register = '/auth/register';
    static auth_refresh_token = '/auth/refresh-token';

    static sms_login = '/sms/login';
    static sms_register = '/sms/register';
    static sms_captcha = '/sms/captcha';

    static profile = '/profile';
    static profile_update_password = '/profile/update-password';
    static profile_update_gender = '/profile/update-gender';
    static profile_update_nickname = '/profile/update-nickname';
    static profile_update_signature = '/profile/update-signature';
    static profile_update_avatar = '/profile/update-avatar';
    static profile_update_local = '/profile/update-local';
    static profile_update_settings = '/profile/update-settings';

    static user_info = '/user/info';
    static user_follow = '/user/follow';
    static user_unfollow = '/user/unfollow';
    static user_search = '/user/search';
    static user_black = '/user/black';
    static user_cancel_black = '/user/cancel-black';
    static user_fans = '/user/fans';
    static user_follows = '/user/follows';
    static user_vehicles = '/user/vehicles';
    static user_albums = '/user/albums';
    static user_medals = '/user/medals';
    static user_my_blacks = '/user/my-blacks';

    static anchor_apply_apply = '/anchor-apply/apply';
    static anchor_apply_info = '/anchor-apply/info';

    static my_room_info = '/my-room/info';
    static my_room_status = '/my-room/live-status';
    static my_room_start_live = '/my-room/start-live';
    static my_room_stop_live = '/my-room/stop-live';
    static my_room_thumbs = '/my-room/thumbs';
    static my_room_upload_thumb = '/my-room/upload-thumb';
    static my_room_delete_thumb = '/my-room/delete-thumb';
    static my_room_live_records = '/my-room/live-records';
    static my_room_last_live_record = '/my-room/last-live-record';
    static my_room_check_stream = '/my-room/check-stream';
    static my_room_earnings = '/my-room/earnings';
    static my_room_earnings_collect = '/my-room/earnings/collect';
    static my_room_live_durations = '/my-room/durations';
    static my_room_live_duration_collect = '/my-room/duration-collect';

    static family = '/family';
    static family_create = '/family/create';
    static family_my_create = '/family/my-create';
    static family_join = '/family/join';
    static family_info = '/family/info';
    static family_users = '/family/users';
    static family_elites = '/family/elites';
    static family_anchors = '/family/anchors';

    static upload_image = '/upload/image';

    static vip_price = '/vip/price';
    static vip_buy = '/vip/buy';

    static nice_number = '/nice-number';
    static nice_number_present = '/nice-number/present';
    static nice_number_my_nice_numbers = '/nice-number/my-nice-numbers';
    static nice_number_mount = '/nice-number/mount';
    static nice_number_buy = '/nice-number/buy';

    static setting = '/setting';

    static content = '/content/';

    static bank = '/bank';
    static bank_list = '/bank/list';
    static bank_create = '/bank/create';
    static bank_delete = '/bank/delete';

    static recharge_gateways = '/recharge/gateways';
    static recharge_rules = '/recharge/rules';
    static recharge_rules_in_app = '/recharge/rules-in-app';
    static recharge_create_order = '/recharge/create-order';
    static recharge_payment = '/recharge/payment';
    static recharge_order_info = '/recharge/order-info';
    static recharge_verify_in_app = '/recharge/verify-in-app';

    static signin = '/signin';
    static signin_signin = '/signin/signin';

    static club_my_joined = '/club/my-joined';
    static club_join = '/club/join';
    static club_users = '/club/users';
    static club_show = '/club/show';
    static club_my_club = '/club/my-club';
    static club_update_announcement = '/club/update-announcement';
    static club_update_name = '/club/update-name';

    static vehicle = '/vehicle';
    static vehicle_hot = '/vehicle/hot';
    static vehicle_discount = '/vehicle/discount';
    static vehicle_buy = '/vehicle/buy';
    static vehicle_present = '/vehicle/present';
    static vehicle_my_vehicles = '/vehicle/my-vehicles';
    static vehicle_mount = '/vehicle/mount';

    static medal_all_medals = '/medal/all-medals';
    static medal_my_medals = '/medal/my-medals';
    static medal_mount = '/medal/mount';
    static medal_unmount = '/medal/unmount';

    static rank_user_rank = '/rank/user-rank';
    static rank_anchor_rank = '/rank/anchor-rank';
    static rank_family_rank = '/rank/family-rank';

    static feedback_submit = '/feedback/submit';

    static room_all = '/room/all';
    static room_list_follow = '/room/list-follow';
    static room_categories = '/room/categories';
    static room_info = '/room/info';
    static room_list_nearby = '/room/list-nearby';
    static room_list_category = '/room/list-category';
    static room_list_hot = '/room/list-hot';
    static room_more = '/room/more';
    static room_search = '/room/search';
    static room_list_choice = '/room/list-choice';
    static room_list_recommend = '/room/list-recommend';
    static room_list_pk = '/room/list-pk';

    static audience_managers = '/audience/managers';
    static audience_quiets = '/audience/quiets';
    static audience_locks = '/audience/locks';
    static audience_guards = '/audience/guards';
    static audience_bag_gifts = '/audience/bag-gifts';
    static audience_guard_price = '/audience/guard-price';
    static audience_buy_guard = '/audience/buy-guard';
    static audience_gifts = '/audience/gifts';
    static audience_present_gift = '/audience/present-gift';
    static audience_top_honour = '/audience/top-honour';
    static audience_online_users = '/audience/online-users';
    static audience_now_contributes = '/audience/now-contributes';
    static audience_contributes = '/audience/contributes';

    static room_manage_remove_manager = '/room-manage/remove-manager';
    static room_manage_add_manager = '/room-manage/add-manager';
    static room_manage_remove_lock = '/room-manage/remove-lock';
    static room_manage_add_lock = '/room-manage/add-lock';
    static room_manage_remove_quiet = '/room-manage/remove-quiet';
    static room_manage_add_quiet = '/room-manage/add-quiet';

    static room_pk_agree = '/room-pk/agree';
    static room_pk_reject = '/room-pk/reject';
    static room_pk_request = '/room-pk/request';
    static room_pk_rand_request = '/room-pk/rand-request';
    static room_pk_types = '/room-pk/types';

    static task_task_list = '/task/task-list';
    static task_receive_award = '/task/receive-award';

    static public_check_upgrade = '/public/check-upgrade';

    static level_user_levels = '/level/user-levels';
    static level_room_levels = '/level/room-levels';

    static slide = '/slide/get-slide';

    static bill_gold_records = '/bill/gold-records';
    static bill_recharges = '/bill/recharges';
    static bill_gift_send_records = '/bill/gift-send-records';

    static red_envelope_send = '/red-envelope/send';
    static red_envelope_handle_lucky = '/red-envelope/handle-lucky';

    static music_search = '/music/search';
    static music_info = '/music/info';

    static lottery_game_games = '/lottery-game/games';

    static post_log = '/log/post';
}

import Vue from 'vue'
import {fetchSettings} from "@/reqeust/api";
import {Setting} from "@/model";

const state = {
    setting: Setting.fromJson(null)
}

const mutations = {
    SET_SETTING: (state, setting) => {
        Vue.set(state, 'setting', setting)
    },
}

const actions = {
    getSetting({commit}) {
        return new Promise((resolve, reject) => {
            fetchSettings().then(data => {
                commit('SET_SETTING', data)
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}

